// import { useState, useEffect } from "react";

import WelcomeBanner from "../components/WelcomeBanner";
import MidBanner from "../components/MidBanner";

import conceptgeneration from "../assets/images/concept-generation.jpg";
import pcba from "../assets/images/pcba.jpg";
import prototyping from "../assets/images/prototyping.jpg";
import ContactForm from "../components/ContactForm";

function WelcomePage() {
  return (
    <section>
      <WelcomeBanner />
      <section id="wrapper">
        <MidBanner
          title="Concept Generation & Product Development"
          image={conceptgeneration}
          wrapper_style="wrapper spotlight style1"
          subtitles={[
            "Turning Innovative Medical & Consumer Device Ideas into Products",
          ]}
          bodies={[]}
          // bodies={[
          //   "We'll facilitate brainstorming sessions to explore your initial ideas and identify unmet needs in the market.  Feasibility Assessment: We'll assess the technical and commercial feasibility of your concept, considering factors like manufacturability, regulatory requirements, and potential costs.  Concept Refinement: Through collaboration, we'll refine your concept, ensuring it is well-defined, user-centered, and aligns with your business goals.  Proof-of-Concept Development: We can help develop a basic prototype to demonstrate the feasibility and functionality of your concept.",
          // ]}
          listItems={[
            "Ideation & Brainstorming: We'll facilitate brainstorming sessions to explore your initial ideas and identify unmet needs in the market.",
            "Feasibility Assessment: We'll assess the technical and commercial feasibility of your concept, considering factors like manufacturability, regulatory requirements, and potential costs.",
            "Concept Refinement: Through collaboration, we'll refine your concept, ensuring it is well-defined, user-centered, and aligns with your business goals.",
            "Proof-of-Concept Development: We can help develop a basic prototype to demonstrate the feasibility and functionality of your concept.",
          ]}
        />
        <MidBanner
          title="Electrical & Software Engineering"
          image={pcba}
          wrapper_style="wrapper spotlight style2"
          subtitles={["Extensive Engineering Expertise"]}
          bodies={[""]}
          listItems={[
            "Hardware Design: Schematic development, PCB layout and design, component selection, and manufacturability analysis.",
            "Firmware Development: Embedded systems programming, low-level drivers, and real-time operating systems.",
            "Software Development: User interfaces, application logic, cloud connectivity, and data security.",
          ]}
        />
        <MidBanner
          title="Prototyping"
          image={prototyping}
          wrapper_style="wrapper spotlight style1"
          subtitles={["Prototyping Services that meets needs and budgets"]}
          bodies={[""]}
          listItems={[
            "Functional Prototyping: Development of more advanced prototypes that mimic the final product's functionality and user experience.",
            "Rapid Prototyping: Quick and cost-effective creation of basic prototypes using techniques like 3D printing and laser cutting.",
            "Software Development",
            "Proof-of-Concept Prototypes: Prototypes designed to demonstrate the feasibility and potential of your concept.",
          ]}
        />

        <ContactForm />
        {/* <MeetupList meetups={loadedMeetups} /> */}
      </section>
    </section>
  );
}

export default WelcomePage;
