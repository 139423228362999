import "../assets/css/main.css";

export default function MidBanner(props) {
  const { title, subtitles, bodies, listItems } = props;
  return (
    <section id="one" className={props.wrapper_style}>
      <div className="inner">
        <img className="image" alt="" src={props.image} />
        <div className="content">
          <h2 className="major">{title}</h2>
          {subtitles.map((subtitle, index) => (
            <h3 className="major" key={`subtitle-${index}`}>
              {subtitle}
            </h3>
          ))}
          {bodies.map((body, index) => (
            <p className="minor" key={`body-${index}`}>
              {body}
            </p>
          ))}
          {listItems && (
            <ul>
              {listItems.map((item, index) => (
                <li key={`list-${index}`}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </section>
  );
}
