import React, { useState, useEffect } from "react";
import "../assets/css/main.css";

import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../config/Firestore";
// import firebase from "firebase/compat/app"
// import firebase from "firebase/compat/app";
// import { serverTimestamp } from "@firebase/firestore";
import ReCAPTCHA from "react-google-recaptcha";

import emailjs from "@emailjs/browser";

const ContactForm = () => {
  useEffect(() => emailjs.init("rdeLr1IgzBOyv3nsJ"), []);

  const [capVal, setCapVal] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    message: "",
    key: "VwkMpSq8D",
    timestamp: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.timestamp = serverTimestamp();
    // Handle form submission logic here (e.g., send data to a server)
    const serviceId = "service_rgb5zp8";
    const templateId = "template_kll3gpe";

    try {
      await emailjs.send(serviceId, templateId, {
        name: formData.name,
        company: formData.company,
        email: formData.email,
        message: formData.message,
      });
    } catch (error) {
      console.log(error);
    }

    try {
      await addDoc(collection(db, "contactForm"), {
        ...formData,
      });
    } catch (error) {
      console.log(error);
    }

    // fetch(
    //   "https://te-website-aa202-default-rtdb.firebaseio.com/formData.json",
    //   {
    //     method: "POST",
    //     body: JSON.stringify(formData),
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // );
    //console.log("Form data submitted:", formData);
    // You can reset the form if needed
    setFormData({
      name: "",
      company: "",
      email: "",
      message: "",
    });

    // Set formSubmitted to true
    setFormSubmitted(true);
  };

  return (
    <section id="footer">
      <div className="inner">
        <h2 className="major">Get in touch</h2>
        {formSubmitted ? (
          <form>
            <div className="fields">
              <div className="field">
                <h2>Message sent. Thank you!</h2>
              </div>
            </div>
          </form>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="fields">
              <div className="field">
                <label>
                  Name:
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <br />

              <div className="field">
                <label>
                  Company:
                  <input
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <br />

              <div className="field">
                <label>
                  Email:
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <br />

              <div className="field">
                <label>
                  Message:
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <br />
              <div className="field">
                <ReCAPTCHA
                  sitekey="6LeBLVIpAAAAAIJY1X7Qg_UXa6tWcbIXq0TnS39i"
                  onChange={(val) => setCapVal(val)}
                />
              </div>

              <div className="field">
                <button type="submit" disabled={!capVal}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}

        <ul className="contact">
          <li className="icon solid fa-phone">(908)-543-4051</li>
          <li className="icon solid fa-envelope">
            contact@tamioengineering.com
          </li>
        </ul>
        <ul className="copyright">
          <li>&copy; 2023 Tamio Engineering, LLC All rights reserved.</li>
        </ul>
      </div>
    </section>
  );
};

export default ContactForm;
