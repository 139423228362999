import React, { useState, useEffect } from "react";

import logo1 from "../assets/images/logo1.png";
import "../assets/css/main.css";

const NavBar = () => {
  const [isSticky, setIsSticky] = useState(false);

  // Sticky Menu Area
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsSticky(scrollTop >= 460);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={`header-section ${
          isSticky ? "is-sticky" : ""
        } d-none d-xl-block`}
      >
        {isSticky && <img src={logo1} alt="Logo" width="200" />}
      </header>
    </>
  );
};

export default NavBar;
